<template>
	<div>
		<Header2022 :navbg='true'></Header2022>
		<div class="content">
			<div class="login-page">
				<div class="logo">
					<img src="../../assets/images/logo-login.png">
				</div>
				<div class="mobile">
					<input type="text" name="mobile" placeholder="请输入手机号码" v-model="mobile" @focus="focus('mobile')"
						@blur="blur('mobile')" :style="mobileStyle">
				</div>
				<div class="code">
					<input type="text" name="code" placeholder="请输入验证码" v-model="code" @focus="focus('code')"
						@blur="blur('code')" :style="codeStyle">
					<el-button :type="loading?'info':'primary'" :disabled="canCode" class="btn" @click="getCode"
						:class="loading?'loading':''">{{codeTitle}}</el-button>
				</div>
				<div class="clause">
					注册代表你已同意
					<div class="privacy-policy" @click="privacyPolicy">
						《隐私条款》
					</div>
				</div>
	<!-- 			<a target="_blank" @click="wxtap">
					<div class="wxbtn">
						<span class="iconfont icon-wechat"></span>
						使用微信扫一扫登录
					</div>
				</a> -->
				<div class="login">
					<el-button class="btn" @click="login" type="primary" :disabled="canLogin">登录</el-button>
				</div>
				<div class="footer">
					<div>Copyright © {{year}} 西安小院科技股份有限公司 版权所有</div>
					<div>陕ICP备18018083号-4</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Header2022 from '@/components/header2022.vue'
	import utils from '@/common/utils.js'
	import {
		mapState
	} from 'vuex'

	export default {
		data() {
			return {
				mobileStyle: {},
				codeStyle: {},
				mobile: "",
				code: "",
				loading: false,
				nsecond: 60,
				appCount: null,
				externalLinks: null,
				url: '',
			}
		},
		components: {
			Header2022,
		},
		watch: {
			// '$route': {
			// 	handler: function(to) {
			// 		if (to.name == "login" && to.query.redirectName) {
			// 			this.url =`http://down.xiaoyuan.com.cn/wechat/index.php?appid=wx88fb329f29e26531&return_uri=http://2023.xiaoyuan.com.cn/${to.query.redirectName}&redirect_uri=http://book.xiaoyuan.com.cn/index.php?route=callback/wechat_proxy&device=pc&response_type=code&scope=snsapi_login&state=guangda_network#wechat_redirect`;
			// 			// this.url=`http://down.xiaoyuan.com.cn/wechat/index.php?appid=wx88fb329f29e26531&return_uri=http://www.xiaoyuan.com.cn${from.fullPath}&redirect_uri=http://live.xiaoyuan.com.cn/index.php?route=callback/wechat_proxy&device=pc&response_type=code&scope=snsapi_login&state=guangda_network#wechat_redirect`;
			// 		} else {
			// 			this.url =
			// 				"http://down.xiaoyuan.com.cn/wechat/index.php?appid=wx88fb329f29e26531&return_uri=http://2023.xiaoyuan.com.cn/member&redirect_uri=http://book.xiaoyuan.com.cn/index.php?route=callback/wechat_proxy&device=pc&response_type=code&scope=snsapi_login&state=guangda_network#wechat_redirect";
			// 		}
			// 	},
			// 	immediate: true
			// },
		},
		computed: {
			...mapState(['ocApi']),
			year: function() {
				return utils.date('Y')
			},
			codeTitle: function() {
				if (this.loading) {
					return this.nsecond + 's'
				} else {
					return "获取验证码"
				}
			},
			canCode: function() {
				if (!this.mobile || this.loading) {
					return true
				}
				return false
			},
			canLogin: function() {
				if (this.mobile && this.code) {
					return false
				} else {
					return true
				}
			},
		},
		created: function() {
			let externalLinks = this.$route.query.url;
			if (externalLinks) {
				this.externalLinks = externalLinks;
			}
		},
		methods: {
			// wxtap() {
			// 	window.open(this.url, '微信登录', 'width=700, height=500,top=200,left=600,')
			// },
			focus: function(e) {
				this[e + 'Style'] = {
					"background-color": "#FFFFFF",
					"border": "1px solid #00D596",
					"border-radius": "10px"
				}
			},
			blur: function(e) {
				this[e + 'Style'] = {
					"background-color": "#F7F7F7",
					"border": "1px solid #F7F7F7",
					"border-radius": "10px"
				}
			},
			getCode: utils.debounce(function() {
				let that = this;
				if (that.canCode) {
					return
				}

				that.$axios.post(that.ocApi + 'account/send_sms', {
						'telephone': that.mobile,
					})
					.then(res => {
						if (res.data.status == 1) {
							that.$message({
								message: res.data.message,
								type: 'success'
							});
							that.loadingCode();
						} else {
							that.$message.error(res.data.message);
						}
					})
					.catch(error => {
						that.$message.error(error.response.data.message);
					});
			}, 500, true),
			loadingCode: function() {
				let that = this
				that.loading = true
				that.appCount = setInterval(() => {
					that.nsecond -= 1;
					if (that.nsecond == 0) {
						clearInterval(that.appCount);
						that.loading = false;
						that.nsecond = 60
					}
				}, 1000)
			},
			login: utils.debounce(function() {
				let that = this;
				let query = {}
				var value = localStorage.getItem("inviterQuery");
				if (value) {
					query = {
						...JSON.parse(value)
					}
				}
				that.$axios.post(that.ocApi + 'account/telephone_login', {
						'account': that.mobile,
						'sms_code': that.code,
						'code': "GW",
						...query
					})
					.then(res => {
						if (res.data.status == 1) {
							if (that.externalLinks) {
								window.location.href = that.externalLinks + '?access_token=' + res.data
									.user_info.access_token;
								return;
							}
							that.$message({
								message: res.data.message,
								type: 'success'
							});
							that.$cookies.set("xy_access_token", res.data.user_info.access_token, "1m", '/','.xiaoyuan.com.cn');
							// that.$cookies.set("xy_access_token",res.data.user_info.access_token);//测试用
							that.$store.dispatch('loginLogout', res.data.user_info);
							if (that.$route.query && that.$route.query.redirectName) {
								that.$router.replace({
									name: that.$route.query.redirectName
								})
							} else {
								that.$router.replace({
									name: "member"
								})
							}
						} else {
							that.$message.error(res.data.message);
						}
					})
					.catch(error => {
						that.$message.error(error.response.data.message);
					});
			}, 1000, true),
			privacyPolicy: utils.debounce(function() {
				this.$router.push({
					name: "privacyPolicy"
				})
			}, 1000, true),

		}
	}
</script>

<style lang="scss" scoped>
	.login-page {
		width: 342px;
		margin: 210px auto 0;
		text-align: center;
	}

	.login-page .logo img {
		width: 111px;
		height: auto;
	}

	.login-page .mobile {
		margin-top: 73px;
	}

	.login-page input {
		background-color: #f7f7f7;
		border: 1px solid #f7f7f7;
		border-radius: 10px;
		color: #505050;
		font-size: 14px;
		padding: 15px 0px 15px 17px;
		box-sizing: border-box;
	}

	.login-page .mobile input {
		width: 100%;
	}

	.login-page .code {
		margin-top: 9px;
		display: flex;
		height: 48px;
		justify-content: space-between;
	}

	.login-page .code input {
		flex: 1;
		margin-right: 12px;
	}

	.login-page .code .btn {
		width: 120px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.login-page .clause {
		text-align: left;
		margin-top: 12px;
		font-size: 13px;
		color: #505050;
	}

	.login-page .clause .privacy-policy {
		color: #00d596;
		display: inline;
		cursor: pointer;
	}

	.login-page .login {
		margin-top: 47px;
	}

	.login-page .login .error {
		font-size: 13px;
		color: #ff0000;
		display: none;
	}

	.login-page .login .btn {
		font-size: 14px;
		color: #ffffff;
		width: 100%;
		height: 48px;
	}

	.login-page .footer {
		margin-top: 190px;
		color: #999999;
		font-size: 12px;
	}

	.wxbtn {
		margin-top: 20px;

		.iconfont {
			color: #02BD5E;
			font-weight: bolder;
			font-size: 16px;
		}
	}
</style>